import React from "react";
import Slider from "react-slick"; 
import styled from "styled-components"

import '../../node_modules/slick-carousel/slick/slick.css';
import '../../node_modules/slick-carousel/slick/slick-theme.css';
import banner_1 from '../assets/images/banner-home.png'

const SliderContainer = styled.div` 
	background: url('${banner_1}'); 
	background-color: #003ea4; 
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: white;
	padding: 10rem 0;
	padding-bottom: 12rem;
    min-height: 40rem;
`

export default () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  
  return (
    <SliderContainer>
      <Slider {...settings}>
        <div>
          <div className="text-center">
			<h1>{'TRANSPORTISTA ESPECIALIZADO'}</h1>
			<div className="red-line"></div>
			<p>MAS DE <b>17 AÑOS</b> DE EXPERIENCIA</p>
			<a  href="/ESP/cotiza" className="buttonRedSlider">{'¡Cotiza Ahora!'}</a>
          </div>
        </div>
        <div>
          <div className="text-center">
			<h1>{'TRANSPORTISTA ESPECIALIZADO'}</h1>
			<div className="red-line"></div>
			<p>MAS DE <b>17 AÑOS</b> DE EXPERIENCIA</p>
			<a  href="/ESP/cotiza" className="buttonRedSlider">{'¡Cotiza Ahora!'}</a>
          </div>
        </div> 
        <div>
          <div className="text-center">
			<h1>{'TRANSPORTISTA ESPECIALIZADO'}</h1>
			<div className="red-line"></div>
			<p>MAS DE <b>17 AÑOS</b> DE EXPERIENCIA</p>
			<a href="/ESP/cotiza" className="buttonRedSlider">{'¡Cotiza Ahora!'}</a>
          </div>
        </div>  
      </Slider>
    </SliderContainer>
  )
}
