import React from "react"

import { Container } from 'reactstrap'
import LayoutEsp from "../../components/layoutEsp"
import SEO from "../../components/seo"
import Slider from '../../components/slider-esp' 
import { Player } from 'video-react'; 
import VideoBanner from '../../assets/images/GT_FinalEspañol.mp4' 
import truckAbsolute2 from '../../assets/images/how.png'
import truckAbsolute from '../../assets/images/truckAbsolute.png' 
import redStar from '../../assets/images/red-star.png' 
import redLocation from '../../assets/images/red-location.png' 
import redQuality from '../../assets/images/red-quality.png'  
import whiteArrow from '../../assets/images/white-arrow.png'  
import whiteRow from '../../assets/images/white-row.png'  
import worldIcon from '../../assets/images/world.png'
import service_1 from '../../assets/images/service1.png'
import service_2 from '../../assets/images/service2.png'
import service_3 from '../../assets/images/service3.png'
import service_4 from '../../assets/images/service4.png'
import service_6 from '../../assets/images/service6.png'
import cert1 from '../../assets/images/cert1.png'
import cert2 from '../../assets/images/cert2.png'
import cert3 from '../../assets/images/cert3.png'
import cert4 from '../../assets/images/cert4.png'
import cert5 from '../../assets/images/cert5.png'
import cert6 from '../../assets/images/cert6.png'
import service_5 from "../../assets/images/service5.png";
 
export default () => (
  <LayoutEsp>
    <SEO title="INICIO" />
    <Slider/> 
	<Player autoPlay >
          <source src={VideoBanner}	 /> 
    </Player>
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
			  </div>
			</div>
		</Container>
	</div> 
	<Container className="pt-4 movil paddingZero">
		<div className="row">    
		</div>
	</Container>
	<div className="crossed-background" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 services">
				<img className="redValue" src={redStar} alt="red icon service"/> 
				<h4 className="serviceH">{'SERVICIOS'}</h4> 
				<p className="serviceP">{'Servicio puerta a puerta'}</p> 
				<p className="serviceP2">{'Mexico - Usa - Canada'}</p> 
				<a className="serviceVisit"  href="/ESP/servicios">{'Visitar seccion'}</a> 
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 serviceImages">
				<div className="row">
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 servicioImage">
						<img  src={service_1} alt="service 1"/>
						 <h4 className="servicioImageTitle">{'CAJA SECA'}</h4>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 servicioImage">
						<img  src={service_2} alt="service 2"/>
						<h4 className="servicioImageTitle">{'REFRIGERADOS'}</h4>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 servicioImage">
						<img  src={service_6} alt="service 4"/>
						<h4 className="servicioImageTitle">{'SERVICIOS PORTUARIOS Y FERROVIARIOS'}</h4>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 servicioImage">
						<img  src={service_3} alt="service 3"/>
						<h4 className="servicioImageTitle">{'CAMA BAJA'}</h4>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 servicioImage">
						<img  src={service_4} alt="service 4"/>
						<h4 className="servicioImageTitle">{'AGENCIA ADUANAL'}</h4>
					</div>
					<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 servicioImage">
						<img  src={service_5} alt="service 6"/>
						<h4 className="servicioImageTitle">{'ALMACÉN'}</h4>
					</div>
				</div>
			  </div>
			</div>
		</Container>
		<img className="truckAbsoluteIndex" src={truckAbsolute} alt="truckAbsolute"/> 
	</div>
	<div className="map-section" >
		<div className="map-background" >
		</div>
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 locations-block locations-set">
				<img className="redValue" src={redLocation} alt="red icon location"/> 
				<h4 className="locationH">{'UBICACIONES'}</h4> 
				<p className="locationP">Estamos donde</p> 
				<p className="locationP"><b>tu nos necesites</b></p> 
				<a className="locationVisit"  href="/ESP/ubicaciones">Visitar seccion</a> 
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 locations-block">
				<div className="row locations-background">
					<h4 className="whiteHLocation">OFICINA <b>CORPORATIVA</b></h4> 
					<hr className="whiteLine"/>
					<p className="locationP2"><b>350 Nina Lee RD</b></p>
					<p className="locationP2">Calexico, CA</p>
					<p className="locationP2">C.P. 92231</p>
					<p className="locationP2">Tel: 760 3570 991</p>
				</div>
			  </div>
			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 locations-block">
				<div className="row locations-backgroundCity"> 
					<img className="redLocation" src={redLocation} alt="red icon location"/> 
					<h4 className="locationHCity">{'CALEXICO'}</h4> 
					<hr className="thinLine"/> 
					<p className="locationPCity"><b>A.G. Warehouse, 350 Nina Lee RD.</b></p>
					<p className="locationPCity">Calexico,&nbsp;&nbsp;&nbsp;&nbsp;CA 92231</p>   
				</div>
			  </div>
			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 locations-block">
				<div className="row locations-backgroundCity"> 
					<img className="redLocation" src={redLocation} alt="red icon location"/> 
					<h4 className="locationHCity">{'MEXICALI'}</h4> 
					<hr className="thinLine"/> 
					<p className="locationPCity"><b>Km 11.5 Carretera Aeropuerto,<br/> Col. Mariano Abasolo</b></p>
					<p className="locationPCity">Mexicali, B.C. &nbsp;&nbsp;&nbsp;&nbsp;21600</p>  
					
				</div>
			  </div>
			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 locations-block">
				<div className="row locations-backgroundCity">
					<img className="redLocation" src={redLocation} alt="red icon location"/> 
					<h4 className="locationHCity">{'OTAY'}</h4> 
					<hr className="thinLine"/> 
					<p className="locationPCity"><b>Eje Poniente #195<br/> Col. Magisterial</b></p>
					<p className="locationPCity">Tijuana,B.C. &nbsp;&nbsp;&nbsp;&nbsp;22470</p>  
				
				</div>
			  </div>
			  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 locations-block">
				<div className="row locations-backgroundCity">
					<img className="redLocation" src={redLocation} alt="red icon location"/> 
					<h4 className="locationHCity">{'LOS ANGELES'}</h4>
					<hr className="thinLine"/>  
					<p className="locationPCity"><b>280 E Del Amo</b></p>
					<p className="locationPCity">Compton,CA &nbsp;&nbsp;&nbsp;&nbsp;90221</p>  
				
				</div>
			  </div>
			</div>
		</Container>
	</div>
	<div className="certification-background" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="redQuality" src={redQuality} alt="red icon quality"/> 
				<h4 className="certH">{'CERTIFICACIONES'}</h4> 
				<p className="certP">Creemos en la <b>mejora continua</b></p>  
				<a className="certificationVisit"  href="/ESP/certificaciones">{'Visitar seccion'}</a> 
				<img className="truckAbsoluteCert" src={truckAbsolute2} alt="truck"/> 
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 certdiv certdivIndex">
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert1} alt="service 1"/>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert2} alt="service 2"/> 
						</div>
					</div> 
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert3} alt="service 4"/> 
						</div>
					</div> 
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert4} alt="service 3"/> 
						</div>
					</div> 
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert5} alt="service 4"/>
						</div> 
					</div> 
					<div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 certImage">
						<div className="row cert-white"> 
							<img  src={cert6} alt="service 6"/> 
						</div>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div> 
	<div className="technology-background" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"> 
					<img className="whiteArrow" src={whiteArrow} alt="white icon arrow"/>  
					<h4 className="technology-H">{'TECNOLOGIA'}</h4> 
					<a className="technology-Visit"  href="/ESP/tecnologia">{'Visitar seccion'}</a>  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
				<div className="row tec-row-info">
					<h4 className="technology-info">OFICINA <b>CORPORATIVA</b></h4> 
					<hr className="whiteLine"/>
					<p className="technology-p">{'Seguridad y protección en todas nuestras cargas; Gutierrez Trucking es una empresa que monitorea de cerca la seguridad en la cadena de suministro y constantemente asegura toda la carga.'}</p> 
					<a href="" className="moreCorporate" ><img className="whiteRow" src={whiteRow} alt="white arrow"/> Ver Mas </a>
				</div>
			  </div>
			</div>
		</Container>
	</div>  
  </LayoutEsp>
)
